.pagination-item {
  color: #b0d6e8;
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  padding: 0.5rem 0.001rem 0.5rem 2rem;
  cursor: pointer;
  bottom: 10px;
}

.pagination-active,
.pagination-item:hover {
  color: #f8a832;
}

.swiperPagination {
  position: absolute;
  bottom: 80px;
  padding-left: 3rem;
}

@media only screen and (max-width: 899px) {
  .SwiperSlider {
    display: flex !important;
    flex-direction: column !important;
  }
}




.swiper-navigation {
  position: absolute;
  bottom: 1%;
  left: -1.2%;
  z-index: 10;

}
@media only screen and (max-width: 899px) {
  .swiper-navigation {
    display: flex;
    justify-content: center;
    position: static;
    /* bottom: -1.6rem; */
    /* margin: 1rem; */
  
  }}

.swiper-prev-button,
.swiper-next-button {
  background-color: transparent; /* Customize button styles */
  padding: 10px 20px;
  border:none; 
  cursor: pointer;
}
