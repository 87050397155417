@media only screen and (max-width: 768px) {
  .swiper-wrapper {
    background-color: aqua;
  }
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.serviceSectionMain {
  background-color:#F6F7FD ;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  /* padding-bottom: 3rem; */
  /* padding-top: 1.5rem; */
}
@media only screen and (max-width: 1300px) {
  .WhyCloudScale{
    transform: scale(0.85) translateY(-50px);
    /* width: 140% !important;
    height: 510px; */
    /* height: 1rem; */
    height: 443px;
    width: 114%;
    margin-left: -7%;
    overflow: visible !important;
  }
}
@media only screen and (max-width: 1188px) {
  .WhyCloudScale{
    transform: scale(0.65) translateY(-100px);
    /* width: 140% !important;
    height: 510px; */
    /* height: 1rem; */
    height: 344px;
    width: 140%;
    margin-left: -20%;
    overflow: visible !important;
  }
}
@media only screen and (max-width: 1000px) {
  .WhyCloudScale{
    transform: scale(0.5) translateY(-160px);
    /* width: 180% !important;
    height: 390px; */
    /* height: 1rem; */
    height: 250px;
    width: 187%;
    margin-left: -44%;
    overflow: visible !important;
  }
}
@media only screen and (max-width: 750px) {
  .WhyCloudScale{
    transform: scale(0.25) translateY(-170px);
    /* width: 300% !important;
    height: 200px; */
    /* height: 1rem; */
    height: 73px;
    width: 302%;
    margin-left: -99%;

    overflow: visible !important;
  }
}
@media only screen and (max-width: 550px) {
  .WhyCloudScale{
    transform: scale(0.2) translateY(-190px);
    /* width: 500% !important;
    height: 150px; */
    /* height: 1rem; */
    height: 65px;
    width: 400%;
    margin-left: -150%;
    overflow: visible !important;
  }
}



