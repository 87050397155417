*, ::after, ::before {
  box-sizing: border-box;
}
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; 
  background: transparent; 
}
body {
  -webkit-user-select: none; 
   -ms-user-select: none; 
  user-select: none; 

  margin: 0;
  font-family: 'Mulish';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, p {
  margin: 0;
  padding: 0;
}