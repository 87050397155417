.bannerMain{
  background-image: linear-gradient(to right, #F1705A 0%, #F0AD56 100%);
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 100%;
}
.bannerContainer{  height: 416px;
}
@media screen and (max-width: 899px) {
  .bannerContainer{
    display: flex;
    height: auto;
    min-height: 416px;
padding-bottom: 4rem;
  }}

