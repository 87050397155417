/* .contactUsContainer {
  background-image: url("../../assets/images/contact-us-bg.jpg");
  background-repeat: no-repeat;
  width: 100%;
  object-fit: cover;
  background-size: cover;;
} */
@media screen and (min-width: 920px) {
  .formStyle {
    display: flex;
    justify-content: end;
  }
}
