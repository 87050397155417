@media only screen and (max-width: 899px) {
  .footerContainer {
    display: flex;
    flex-direction: column;
    max-width: none;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 1200px) {
  .footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    max-width: none !important;
    margin: 0 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media only screen and (min-width: 1536px) {
  .footerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    max-width: none !important;
    margin: 0 !important;
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }
}

