.projects-section {
  margin: auto;
  overflow: hidden;

}
.ProjectSectionWrapper{
  background-image: #fff;
  background-repeat: no-repeat;
  width: 100%;
  object-fit: cover;
  background-size: cover;;
  


}

.projects-section .projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.projects-section .projects-container .subtitle {
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 1.5em;
}

.projects-section .projects-container .projects-content {
  position: relative;
  padding-bottom: 10rem;
}
.card-image-style{
  max-width: 85px;
  max-height: 85px;
}

.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card {
  position: relative;
  flex-basis: 44%;
  border-radius: 10px;
  margin-bottom: 2rem;
  display: flex;
  padding: 1rem 1rem;
  margin: 0 1rem;
  box-shadow: 0 3px 10px 0 rgb(2 34 131 / 15%);
  height: 100%;
  min-height: 16rem;
  flex-direction: column;
  align-items: center;
}

.swiper-backface-hidden .swiper-slide {
  padding: 0.5rem;
}
.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-image
  img {
  height: 80px;
}

.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-content {
    height: 100%;
  position: relative;
  text-align: left;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-content
  .card-title {
    text-align: center;
    min-height: 38px;
    color: 
    
#F1705A !important;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
}
@media only screen and (max-width: 1096px) and (min-width: 1001px){
  .projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-content
  .card-title {
  height: 38px;

  }
}
@media only screen and (max-width: 1401px) and (min-width: 1298px){
  .projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-content
  .card-title {
  height: 38px;

  }
}


.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-content
  .card-description {
  margin-bottom: 1rem;
  font-size: 13px;
  color: var(--secondary-text);
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: justify;
  flex-basis: 140px;
}

.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .card-content
  .learn-more {
  position: absolute;
  bottom: -10px;
  text-decoration: none;
  color: var(--primary-text);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: flex;
  align-items: baseline;
  padding: 0.5rem;
  font-size: 15px;
  padding-bottom: 1rem;
}

.projects-section
  .projects-container
  .projects-content
  .industry-wrapper
  .project-card
  .background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -2;
  border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
  #industryCards {
    width: 50% !important;

  }
}
@media only screen and (max-width: 800px) {
  #industryCards {
    width: 100% !important;

  }
}
