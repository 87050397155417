@media only screen and (max-width: 899px) {
  .Direction {
    flex-direction: column !important;
  }
}
@media only screen and (max-width: 899px) {
  .textCenterGoogleCLoud {
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) and (min-width:899px) {
  .BannerCards {
   min-width: 124.72px;
  }
}
@media only screen and (max-width:899px) {
  .BannerCards {
  margin-top: 15px;
  margin-bottom: 15px;
  }
}

/* @media only screen  and (min-width:900px) {
  .BannerCards {
height: 164;  }
}
@media only screen  and (max-width:900px) {
  .BannerCards {
height: 144;  }
} */


@media only screen and (min-width:1181px) {
  .BannerImageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width:50%;
  }
}
@media only screen and (min-width:1181px) {
  .BannerTextWrapper{
    width:50%
  }
}
@media only screen and (max-width:1181px) {
  .BannerImageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width:55%
  }
}
@media only screen and (max-width:1181px) {
  .BannerTextWrapper{
    width:45%
  }
}
@media only screen and (max-width:1165px) {
  .BannerImageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width:60%
  }
}
@media only screen and (max-width:1165px) {
  .BannerTextWrapper{
    width:40%
  }
}
@media only screen and (max-width:899px) {
  .BannerImageWrapper{
     /* display: flex;
    align-items: center;
    justify-content: center; */
    height: 360px;
    width:100%
  }
}
@media only screen and (max-width:899px) {
  .BannerTextWrapper{
    width:100%
  }
}
@media only screen and (min-width:991px) {
  .BannerCards{
    height:150px;
  }
}
@media only screen and (max-width:991px) {
  .BannerCards{
    height:160px;
  }
}
@media only screen and (max-width:899px) {
  .BannerCards{
    height:150px;
  }
}
@media only screen and (max-width:482px) {
  .BannerCards{
    height:160px;
  }
}
@media only screen and (max-width:459px) {
  .BannerCards{
    height:180px;
  }
}
@media only screen and (max-width:400px) {
  .BannerCards{
    height:195px;
  }
}

@media only screen and (min-width:991px) {
  .BannerImageWrapper{
    height: 332.75px;  
  }
}
@media only screen and (max-width:991px) and (min-width:899px) {
  .BannerImageWrapper{
    height: 351.94px;  
  }
}
/* @media only screen and (max-width:970px) {
  .BannerImageWrapper{
    height: 371.13px;  
  }
} */
/* @media only screen and (max-width:903px) {
  .BannerImageWrapper{
    height: 390.31px;  
  }
} */
@media only screen and (max-width:350px) {
  .BannerImageWrapper{
    height: auto;  
  }
} 




