.rulerBlack{
  background-color: #858585;
      width: 20rem;
      height: 1px;
       border:none;
      
    }
    .rulerOrange {
   margin-top:-0.7rem;
      width: 7rem;
      height: 5px;
      border:none;
      background-color: #F1705A; /* Just for visibility */
    }
@media screen and (max-width : 450px) {
  .rulerBlack{
    background-color: #858585;
        width: 15rem;
        height: 1px;
         border:none;
        
      }
      .rulerOrange {
     margin-top:-0.7rem;
        width: 4rem;
        height: 5px;
        border:none;
        background-color: #F1705A; /* Just for visibility */
      }
  
  
}