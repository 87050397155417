.List {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  padding: 10px;
  font-size: 1rem;
  transition: all 0.7s ease-out;
  cursor: pointer;
  display: flex;
}

.css-10d9dml-MuiTabs-indicator {
  width: 0px !important;
}
.item {
  opacity: 1 !important;
  /* border-bottom: 1px solid white !important; */
  /* border-left: 1px solid white !important; */
  width: 100%;
  text-align: left !important;
  align-items: flex-start !important;
  color: unset !important;
}

.tabTitle {
  justify-content: center !important;
  padding: 1.5rem;
  color: #fff !important;
  border-left: 1px solid white !important;;
}

.active {
  background-color: #F8842D !important;
  color: #fff !important;
}

.itemIcon {
  font-size: 18px;
  height: 29px;
  width: 42px;
}
.arrowdiv{
  display: flex;
align-items: center;}
.pointCss{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 80%;
}
.ulCSS{
  margin-top: 1rem;
}
@media only screen and (max-width: 1000px) {
  .OurExpertiseScale{
    transform: scale(0.75) translateY(-100px);
    width: 140% !important;
    height: 473px;
    /* height: 1rem; */
    overflow: visible !important;
  }

}

@media only screen and (max-width: 700px) {
  .OurExpertiseScale{
    transform: scale(0.5) translateY(-100px);
    width: 180% !important;
    height: 300px;
    /* height: 1rem; */
    overflow: visible !important;
  }
}
@media only screen and (max-width: 550px) {
  .OurExpertiseScale{
    transform: scale(0.25) translateY(-200px);
    width: 300% !important;
    height: 100px;
    /* height: 1rem; */
    overflow: visible !important;
  }
}
@media only screen and (max-width: 350px) {
  .OurExpertiseScale{
    transform: scale(0.2) translateY(-250px);
    width: 500% !important;
    height: 100px;
    /* height: 1rem; */
    overflow: visible !important;
  }
}


