:root {
  --primary-text: #3C4858;
  --secondary-text: #888888;
  

}

.swiper-3d .swiper-slide-shadow {
  background: none;
}

.key-features-container .swiper .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #c7c5c5;
  cursor: pointer;
  opacity: 1;
  margin: 0 30px;
}

.key-features-container
  .swiper
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #03a9f4;
}

@keyframes circles {
  0% {
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
