@media only screen and (max-width: 700px), (max-width: 899px) {
  .OurSection {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
  }
}

@media only screen and (max-width: 700px), (max-width: 899px) {
  #ChatSection {
    margin: 0px !important;
  }
}

@media only screen and (max-width: 899px) {
  .chatImage {
    display: flex;
    justify-content: center;
  }
}
.ourDiffHeaderWrapper{

background-color: #fff;
  width: 100%;
background-size: cover;
background-repeat: no-repeat;
object-fit: cover;
}
.mainContainerBox {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  margin-bottom: 1rem;
}
.typographyMainSection {
  color: #0d0d0d;
  font-weight: bold;
  text-align: left;
  font-size: 13px;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 1044px), (max-width: 899px) {
  .typographyMainSection {
    height: 170.8px;
  }
}
.ourDiffHeader {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-weight: light;
  color: rgb(27, 4, 4);
}
.listItem {
  color: #707070;
  line-height: 1.3;
  font-size: 13px;
  font-weight: light;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
