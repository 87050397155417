.brand-wrapper {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.brand-item {
  width: 70px;
  height: 25px;
  margin: 2rem;
}

.statistics {
  padding-top: 5rem;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 899px) {
  .statistics {
    box-shadow: none;
    border-bottom-left-radius: 0rem;
    border-top-left-radius: 0rem;
    margin-left: 0rem;
  }
}

.statistics .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statistics .container .item1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 25%;
}
.statistics .container .item2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 25%;
}
.statistics .container .item3{
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  width: 25%;
}
.statistics .container .item4{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
}
.statistics .company-statistics {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statistics .company-statistics img {
  height: auto;
  max-width: 100%;
}

.statistics .company-statistics .company {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
}

.statistics .company-statistics .statistics-title {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 800;
  color: #ffffff;
  font-size: 22px;
}

@media only screen and (max-width: 700px),
  (max-width: 400px),
  (max-width: 899px) {
  .statistics .container .item1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 700px),
  (max-width: 400px),
  (max-width: 899px) {
  .statistics .container .item2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

  }
}

@media only screen and (max-width: 700px),
  (max-width: 400px),
  (max-width: 899px) {
  .statistics .container .item3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

  }
}

@media only screen and (max-width: 700px),
  (max-width: 400px),
  (max-width: 899px) {
  .statistics .container .item4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

  }
}
@media screen and (min-width: 1535px) {
  .statistics .container{
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (max-width: 1535px) {
  .statistics .container{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.statistics .statistic-number {
  font-size: 30px;
  font-weight: 899;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.statistics .statistic-title {
  text-align: center;
  font-size: 13px;
  color: #fff;
  margin-bottom: 0;
  margin-top: 20px;
  font-weight: 899;
}

.industries-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  position: relative;
}

.industries-section .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.industries-section .container .title {

  font-size: 37px;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  text-align: center;
}

.industries-section .container .parent-content {
  width: 100%;
}
@media screen and (min-width: 1535px) {
  .industries-section .container .parent-content {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
@media screen and (max-width: 1535px) {
  .industries-section .container .parent-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}


.industries-section .container .parent-content .content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 5rem;
  margin: auto;
}

.industries-section .container .parent-content .content .industry-card {
  flex-basis: 23%;
  background-image: linear-gradient(to right, #fff 10%, #fff 100%);
  border-radius: 10px;
  display: flex;
  box-shadow: 0 3px 30px 0 rgb(2 34 131 / 15%);
  padding: 1rem 1.5rem;
  height: 100px;
  transition: 0.3s;
  position: relative;
  align-items: center;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card
  .card-image {
  margin-right: 0.5rem;
  width: 80px;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card
  .card-image
  img {
  width: auto;
  height: 40px;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card
  .card-content {
  max-width: 80%;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card
  .card-content
  .card-title {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card
  .checkmark-container {
  position: absolute;
  top: -11px;
  right: 15px;
  text-align: center;
  border-radius: 1rem;
  opacity: 0;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover
  .card-image
  img {
  filter: brightness(0) invert(1);
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover
  .card-content
  .card-title {
  color: #fff;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover
  .checkmark-container {
  opacity: 1;
  transition: 0.3s;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-1
  .checkmark-container {
  background-color: #b9e8fd;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-1 {
  background-image: linear-gradient(to right, #4bc6fe 10%, #03a9f4 100%);
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-2
  .checkmark-container {
  background-color: #e9f9d5;
}

.industries-section .container .parent-content .content .industry-card:hover {
  box-shadow: 0 5px 35px 0 rgb(2 34 131 / 15%);
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-2 {
  background-image: linear-gradient(to right, #8bc34a 10%, #b9ea80 100%);
}

.industries-section .container .parent-content .content .industry-card:hover {
  box-shadow: 0 5px 35px 0 rgb(2 34 131 / 15%);
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-3 {
  background-image: linear-gradient(to right, #ff9800 10%, #f9b146 100%);
}

.industries-section .container .parent-content .content .industry-card:hover {
  box-shadow: 0 5px 35px 0 rgb(2 34 131 / 15%);
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-3
  .checkmark-container {
  background-color: #fde7c6;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover
  .checkmark-container {
  opacity: 1;
  transition: 0.3s;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-4 {
  background-image: linear-gradient(to right, #f92c8b 10%, #f566a8 100%);
}

.industries-section .container .parent-content .content .industry-card:hover {
  box-shadow: 0 5px 35px 0 rgb(2 34 131 / 15%);
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover.card-4
  .checkmark-container {
  background-color: #ffd2e7;
}

.industries-section
  .container
  .parent-content
  .content
  .industry-card:hover
  .checkmark-container {
  opacity: 1;
  transition: 0.3s;
}

.industries-section .waves-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 72%;
  z-index: -1;
}

.contact-section {
  padding: 0rem 0 0;
  overflow: hidden;
  position: relative;
  background-color: #fff;
}

.support-container {
  position: relative;
  display: flex;
}

.support-container .mac-image {
  width: 40%;
}

.support-container .content-container .title {
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 3rem;
}

.support-container .content-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 2rem;
  margin-left: 2rem;
}

.support-container .content-container .form-content,
.support-container .content-container .wpcf7-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 95%;
}

.support-container .content-container .form-content .button-row,
.support-container .content-container .wpcf7-form .button-row {
  text-align: right;
  flex-basis: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 2rem;
}

.background-rectangle {
  position: absolute;
  top: -31%;
  left: 0;
  width: 100%;
  height: 121%;
  background-image: linear-gradient(249deg, #022283 48%, #0474e5 78%);
  z-index: -1;
  clip-path: polygon(100% 32%, 100% 69%, 0 76%, 0 40%);
}

@media only screen and (max-width: 1023px) {
  .brand-wrapper {
    width: 95%;
  }
}

@media only screen and (max-width: 700px) {
  #card1 {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 1100px) {
  #card1 {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 700px) {
  #card2 {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 1100px) {
  #card2 {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 700px) {
  #card3 {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 1100px) {
  #card3 {
    margin-bottom: 1rem;
  }
}
@media only screen and (max-width: 700px) {
  #card4 {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 1100px) {
  #card4 {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 700px) {
  #content {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1023px) {
  #content {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1130px) {
  #content {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 700px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 899px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
.companyindusWeWorkSection{
  background-image: url("../../assets/images/orangebg.jpg");
  padding-bottom: 5rem;
  width: 100%;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
}
.LatestProject{ 
  background-color:
  #F6F7FD;
 width: 100%;
 background-size: cover;
 object-fit: cover;
 background-repeat: no-repeat;
  }
.industriesWeWorkImage{
  max-width: 80px;
  max-height: 80px;
}
